import { TweenMax } from 'gsap';
import './style.scss';

class Contact {
  constructor() {
    this.$el = document.querySelector('.Contact');
    this.$mail = this.$el.querySelector('.Contact-mail');
    this.$mailTooltip = this.$el.querySelector('.Contact-mailTooltip');

    this.init();
  }

  init() {
    TweenMax.set(this.$mailTooltip, { autoAlpha: 0 });

    this.bindEvents();
  }

  bindEvents() {
    this.$mail.addEventListener('click', e => {
      this.onMailClick(e);
    });
  }

  onMailClick(e) {
    e.preventDefault();

    const { email } = e.srcElement.dataset;

    this.animateTooltip();
    this.copyToClipboard(email);
  }

  animateTooltip() {
    const tl = new TimelineMax();

    tl.fromTo(
      this.$mailTooltip,
      1,
      {
        y: 18,
        autoAlpha: 0
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: Quart.easeOut
      }
    );

    tl.to(
      this.$mailTooltip,
      0.8,
      {
        autoAlpha: 0,
        ease: Quart.easeOut
      },
      0.6
    );
  }

  copyToClipboard(string) {
    const el = document.createElement('input');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  animateIn() {
    this.$el.classList.add('is-visible');
  }
}

export default new Contact();
