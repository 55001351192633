import {
  Renderer,
  Camera,
  Transform,
  Geometry,
  Program,
  Mesh,
  Color,
  Texture
} from "ogl";
import { TweenMax } from "gsap";
import { createTextCanvas } from "./utils";
import gui from "@/utils/gui";
import { damp, clamp } from "@/utils/math";
import vaporVertex from "./shaders/vapor.vert";
import vaporFragment from "./shaders/vapor.frag";
import "./style.scss";

class WebGL {
  constructor({ $el, size }) {
    this.fontSizes = {
      large: 120,
      medium: 90,
      small: 68
    };

    this.size = size;

    this.$el = $el;

    const parentWidth = this.$el.parentNode.offsetWidth;
    const parentHeight = this.$el.parentNode.offsetHeight;

    this.width = parentWidth;
    this.height = parentHeight;

    this.settings = {
      ratio: 0,
      distortionRatio: 0
    };

    this.isMouseOver = false;

    this.init();
    this.initGUI();
    this.bindEvents();
  }

  init() {
    const { width, height } = this;

    const dpr = clamp(window.devicePixelRatio, 1, 2) * 2;

    this.renderer = new Renderer({
      canvas: this.$el,
      width,
      height,
      antialias: true,
      alpha: false,
      dpr
    });

    this.gl = this.renderer.gl;
    this.gl.clearColor(1.0, 1.0, 1.0, 1.0);

    this.camera = new Camera(this.gl, { fov: 35 });
    this.camera.position.set(0, 0, 0);
    this.camera.lookAt([0, 0, 0]);

    this.initMouse();

    this.resize();

    this.scene = new Transform();

    const fontSize = this.fontSizes[this.size];

    const canvasText = createTextCanvas({
      text: "Bonjour",
      fontSize,
      width,
      height
    });

    const texture = new Texture(this.gl, { generateMipmaps: false });
    const img = new Image();

    img.src = canvasText.toDataURL();
    img.onload = () => (texture.image = img);

    const geometry = new Geometry(this.gl, {
      position: {
        size: 3,
        data: new Float32Array([-1, -1, 0, 3, -1, 0, -1, 3, 0])
      },
      uv: {
        size: 2,
        data: new Float32Array([0, 0, 2, 0, 0, 2])
      }
    });

    this.program = new Program(this.gl, {
      vertex: vaporVertex,
      fragment: vaporFragment,
      uniforms: {
        uTime: { value: 0 },
        uRatio: { value: this.settings.ratio },
        uMaskRatio: { value: 0 },
        uDistortionRatio: { value: this.settings.distortionRatio },
        uMouseCoord: {
          value: [this.mousePosition.x, this.mousePosition.y]
        },
        tMap: { value: texture }
      }
    });

    const mesh = new Mesh(this.gl, { geometry, program: this.program });
    mesh.setParent(this.scene);

    requestAnimationFrame(this.update.bind(this));
  }

  initMouse() {
    const elOffset = this.$el.getBoundingClientRect();

    this.centerMousePosition = {
      x: elOffset.left + elOffset.width / 2,
      y: elOffset.top + elOffset.height / 2
    };

    this.mousePosition = {
      ...this.centerMousePosition
    };

    this.smoothedMousePosition = {
      ...this.mousePosition
    };
  }

  initGUI() {
    const folder = gui.addFolder("Vapor");

    const update = () => {
      this.program.uniforms.uRatio.value = this.settings.ratio;
    };

    folder.add(this.settings, "ratio", 0, 1).onChange(update);
    folder.open();
  }

  animateIn() {
    TweenMax.to(this.program.uniforms.uRatio, 1.8, {
      value: 1,
      ease: Quad.easeOut
    });
  }

  update(t) {
    requestAnimationFrame(this.update.bind(this));

    this.program.uniforms.uTime.value = t * 0.001;

    this.updateMouse();

    this.renderer.render({
      scene: this.scene,
      camera: this.camera
    });
  }

  updateMouse() {
    const smoothSpeed = 0.045;

    this.smoothedMousePosition.x = damp(
      this.smoothedMousePosition.x,
      this.mousePosition.x,
      smoothSpeed
    );

    this.smoothedMousePosition.y = damp(
      this.smoothedMousePosition.y,
      this.mousePosition.y,
      smoothSpeed
    );

    const mouseCoords = this.mouseToCoords(this.smoothedMousePosition);
    this.program.uniforms.uMouseCoord.value = [mouseCoords.x, mouseCoords.y];
  }

  bindEvents() {
    window.addEventListener("resize", this.resize.bind(this), false);

    this.$el.addEventListener("mousemove", e => {
      this.handleMovement(e);
    });

    this.$el.addEventListener("mouseenter", e => {
      this.onMouseEnter(e);
    });

    this.$el.addEventListener("mouseleave", e => {
      this.onMouseLeave(e);
    });
  }

  handleMovement(e) {
    if (!this.isMouseOver) {
      return;
    }

    this.mousePosition.x = e.clientX;
    this.mousePosition.y = e.clientY;
  }

  onMouseEnter(e) {
    this.isMouseOver = true;

    const tl = new TimelineMax();

    tl.to(this.program.uniforms.uDistortionRatio, 1.4, {
      value: 1.0,
      ease: Cubic.easeOut
    });

    tl.to(
      this.program.uniforms.uMaskRatio,
      1,
      { value: 1.0, ease: Quart.easeOut },
      0
    );
  }

  onMouseLeave(e) {
    this.isMouseOver = false;

    const tl = new TimelineMax();

    tl.to(this.program.uniforms.uDistortionRatio, 1.4, {
      value: 0,
      ease: Expo.easeOut
    });

    tl.to(
      this.program.uniforms.uMaskRatio,
      1,
      { value: 0, ease: Cubic.easeInOut },
      0
    );

    this.mousePosition = { ...this.centerMousePosition };
  }

  mouseToCoords(mousePosition) {
    const { x, y } = mousePosition;

    const offset = this.$el.getBoundingClientRect();

    const coords = {
      x: ((x - offset.left) / this.width) * 2 - 1,
      y: 1 - ((y - offset.top) / this.height) * 2
    };

    return coords;
  }

  resize() {}
}

export default WebGL;
