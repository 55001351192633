import './style.scss';

class Work {
  constructor() {
    this.$el = document.querySelector('.Work');
  }

  animateIn() {
    const tl = new TimelineMax();

    tl.add(() => {
      this.$el.classList.add('is-visible');
    });

    return tl;
  }
}

export default new Work();
