export function getSize() {
  const width = window.innerWidth;
  let size = "large";

  if (width <= 420) {
    size = "small";
  } else if (width <= 900) {
    size = "medium";
  }

  return size;
}
