import "./style.scss";

import { TweenMax } from "gsap";
import Hero from "@/components/Hero";
import Work from "@/components/Work";
import Contact from "@/components/Contact";
import { getSize } from "@/utils/device";

class Home {
  constructor({ $el }) {
    this.$el = $el;
  }

  init() {
    Hero.init();

    TweenMax.delayedCall(0.3, this.animateIn, [], this);
  }

  animateIn() {
    TweenMax.set(this.$el, { autoAlpha: 1 });

    const tl = new TimelineMax();

    tl.add(() => {
      Hero.animateIn();
    }, 0);

    tl.add(() => {
      Work.animateIn();
    }, 0.8);

    tl.add(
      () => {
        Contact.animateIn();
      },
      getSize() === "large" ? 1.2 : 1.4
    );
  }
}

export default Home;
