import "./style.scss";
import WebGL from "@/webgl";
import { getSize } from "@/utils/device";

class Hero {
  constructor() {
    this.$el = document.querySelector(".Hero");
  }

  init() {
    const size = getSize();

    this.webGL = new WebGL({
      $el: this.$el.querySelector(".WebGL"),
      size
    });
  }

  animateIn() {
    const tl = new TimelineMax();

    tl.add(() => {
      this.webGL.animateIn();
    });

    tl.add(() => {
      this.$el.classList.add("is-visible");
    }, 0.4);

    return tl;
  }
}

export default new Hero();
