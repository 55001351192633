import FontFaceObserver from "fontfaceobserver";
import "./style.scss";
import Home from "@/pages/home";

class App {
  constructor() {
    this.$el = document.querySelector(".App");

    this.home = new Home({ $el: this.$el.querySelector(".Home") });
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    new FontFaceObserver("Montserrat", { weight: 700 }).load().then(() => {
      this.home.init();
    });
  }
}

export default new App();
